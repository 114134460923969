import config from '../../config';

export type AiFlags = {
  aiErrorExplanationEnabled: boolean;
  aiSolutionExplanationEnabled: boolean;
};

type Group = AiFlags & {
  id: number;
  status: string;
};

type GroupHubResponse = {
  groupsForCurrentUser: Group[];
};

export const getUserEnabledForAiFlags = async (): Promise<AiFlags> => {
  const groupsGraphUrl = config.urls.groupHubApi;

  let responseBody: { data: GroupHubResponse };
  try {
    const response = await fetch(groupsGraphUrl, {
      body: JSON.stringify({
        query: `
          query {
            groupsForCurrentUser {
              id
              status
              aiErrorExplanationEnabled
              aiSolutionExplanationEnabled
            }
          }`,
      }),
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
    });
    responseBody = await response.json();

    const groups = responseBody.data.groupsForCurrentUser.filter(
      (group) => group.status !== 'cancelled',
    );
    return {
      aiSolutionExplanationEnabled: groups.every(
        (group) => group.aiSolutionExplanationEnabled,
      ),
      aiErrorExplanationEnabled: groups.every(
        (group) => group.aiErrorExplanationEnabled,
      ),
    };
  } catch (error) {
    return {
      aiSolutionExplanationEnabled: false,
      aiErrorExplanationEnabled: false,
    };
  }
};
